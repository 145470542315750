/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "apiBoSupportToolsFrontpage",
            "endpoint": "https://8qh6cz1um9.execute-api.us-east-1.amazonaws.com/prd",
            "region": "us-east-1"
        }
    ],
    "aws_cognito_identity_pool_id": "us-east-1:1ab150f1-3dd5-495e-ad0d-6a1237d276d8",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_5XM8R8R0O",
    "aws_user_pools_web_client_id": "744fdlhra6ulq0rhc8275jmlms",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "amplifyfrontapp-20201115210723-hostingbucket-prd",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d9vnievayu3vj.cloudfront.net"
};


export default awsmobile;
