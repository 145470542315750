<template>
  <div id="app">
    <div v-if="!signedIn">
        <amplify-authenticator></amplify-authenticator>
    </div>
    <div v-if="signedIn">
      <amplify-sign-out class="signout"></amplify-sign-out>

      <form name="formMain">
        <select @change="onChangeOperation">
          <option v-for="(operation, index) in operations" :key="index" :value="operation.value" :disabled="operation.disabled">
            {{ operation.text }}
          </option>
        </select>

        <div name="operationPanel">
          <div v-if="this.selected_operation == 'ope_InitOrganizations'">
            <div class="access-key">
              <div class="_ak">
                <div class="_f-left">
                  <label class="form-element__label" for="resolutionLeft">アクセスキー</label>
                </div>
                <div class="_f-right">
                  <input type="text" v-model="access_key" name="AccessKey" placeholder="AKIxxxxxx" maxlength="50" autofocus required>
                </div>
              </div>
            </div>
            <div class="secret-key">
              <div class="_sk">
                <div class="_f-left">
                  <label class="form-element__label" for="resolutionLeft">シークレットキー</label>
                </div>
                <div class="_f-right">
                  <input type="text" v-model="secret_key" name="SecretAccessKey" placeholder="xxxxxxxxxx" maxlength="50">
                </div>
              </div>
            </div>
            <div>
              <input type="checkbox" id="is-create-cur-user" value="is-create-cur-user" v-model="is_create_cur_user">
              <label for="is-create-cur-user">CURユーザーを作成しますか？</label>
            </div>
            <div>
              <p>利用する処理(Process)を選択してください。</p>
              <select @change="onChangeProcess">
              <option v-for="(process, index) in processes" :key="index" :value="process.value" :disabled="process.disabled">
                {{ process.text }}
              </option>
              </select>
            </div>
            <p>{{ result_message }} </p>
            <br/>

            <button type="button" v-on:click="this.setupPayerAccount">実行</button>
          </div> 
          <div v-if="this.selected_operation == 'ope_CreateChildren'">
            <div class="access-key">
              <div class="_ak">
                <div class="_f-left">
                  <label class="form-element__label" for="resolutionLeft">アクセスキー</label>
                </div>
                <div class="_f-right">
                  <input type="text" v-model="access_key" name="AccessKey" placeholder="AKIxxxxxx" maxlength="50" autofocus required>
                </div>
              </div>
            </div>
            <div class="secret-key">
              <div class="_sk">
                <div class="_f-left">
                  <label class="form-element__label" for="resolutionLeft">シークレットキー</label>
                </div>
                <div class="_f-right">
                  <input type="text" v-model="secret_key" name="SecretAccessKey" placeholder="xxxxxxxxxx" maxlength="50">
                </div>
              </div>
            </div>
            <div>
                <div class="_f-left">
                  <label class="form-element__label" for="resolutionLeft">追加したい子アカウント群を改行区切りで入力</label>
                </div>
                <div class="_f-right">
                  <textarea v-model="child_accounts" placeholder="hogefuga"></textarea>
                </div>
            </div>

            <p>{{ result_message }} </p>
            <br/>

            <button type="button" v-on:click="this.createChildrenAccounts">実行</button>
          </div> 
          <div v-if="this.selected_operation == 'ope_MoveChildren'">
            <div class="access-key">
              <div class="_ak">
                <div class="_f-left">
                  <label class="form-element__label" for="resolutionLeft">アクセスキー</label>
                </div>
                <div class="_f-right">
                  <input type="text" v-model="access_key" name="AccessKey" placeholder="AKIxxxxxx" maxlength="50" autofocus required>
                </div>
              </div>
            </div>
            <div class="secret-key">
              <div class="_sk">
                <div class="_f-left">
                  <label class="form-element__label" for="resolutionLeft">シークレットキー</label>
                </div>
                <div class="_f-right">
                  <input type="text" v-model="secret_key" name="SecretAccessKey" placeholder="xxxxxxxxxx" maxlength="50">
                </div>
              </div>
            </div>
            <div>
                <div class="_f-left">
                  <label class="form-element__label" for="resolutionLeft">追加したい子アカウント群（ML）を改行区切りで入力</label>
                </div>
                <div class="_f-right">
                  <textarea v-model="child_accounts" placeholder="hogefuga@aws.cloudack.jp"></textarea>
                </div>
            </div>

            <p>{{ result_message }} </p>
            <br/>

            <button type="button" v-on:click="this.moveChildrenAccounts">実行</button>
          </div> 
          <div v-if="this.selected_operation == 'ope_EndChildren'">
            <div class="access-key">
              <div class="_ak">
                <div class="_f-left">
                  <label class="form-element__label" for="resolutionLeft">アクセスキー</label>
                </div>
                <div class="_f-right">
                  <input type="text" v-model="access_key" name="AccessKey" placeholder="AKIxxxxxx" maxlength="50" autofocus required>
                </div>
              </div>
            </div>
            <div class="secret-key">
              <div class="_sk">
                <div class="_f-left">
                  <label class="form-element__label" for="resolutionLeft">シークレットキー</label>
                </div>
                <div class="_f-right">
                  <input type="text" v-model="secret_key" name="SecretAccessKey" placeholder="xxxxxxxxxx" maxlength="50">
                </div>
              </div>
            </div>
            <div>
                <div class="_f-left">
                  <label class="form-element__label" for="resolutionLeft">解除したい子アカウント（ML）群を改行区切りで入力</label>
                </div>
                <div class="_f-right">
                  <textarea v-model="child_accounts" placeholder="hogefuga@aws.cloudack.jp"></textarea>
                </div>
            </div>

            <p>{{ result_message }} </p>
            <br/>

            <button type="button" v-on:click="this.endChildrenAccounts">実行</button>
          </div> 
          <div v-if="this.selected_operation == 'ope_SetupChildAccount'">
            <div class="access-key">
              <div class="_ak">
                <div class="_f-left">
                  <label class="form-element__label" for="resolutionLeft">アクセスキー</label>
                </div>
                <div class="_f-right">
                  <input type="text" v-model="access_key" name="AccessKey" placeholder="AKIxxxxxx" maxlength="50" autofocus required>
                </div>
              </div>
            </div>
            <div class="secret-key">
              <div class="_sk">
                <div class="_f-left">
                  <label class="form-element__label" for="resolutionLeft">シークレットキー</label>
                </div>
                <div class="_f-right">
                  <input type="text" v-model="secret_key" name="SecretAccessKey" placeholder="xxxxxxxxxx" maxlength="50">
                </div>
              </div>
            </div>
            <div class='contract-type'>
              <p>契約種別</p>
              <input type="radio" id="contract-type" value="contract-type-invoice" v-model="contract_type">
              <label for="contract-type-invoice">請求代行・四次元請求代行</label>
              <!-- <input type="radio" id="contract-type" value="contract-type-4th-dimension-invoice" v-model="contract_type">
              <label for="contract-type-4th-dimension-invoice">四次元請求代行</label> -->
              <input type="radio" id="contract-type" value="contract-type-kddi-edp" v-model="contract_type">
              <label for="contract-type-kddi-edp">KDDI EDP</label>
              <input type="radio" id="contract-type" value="contract-type-billing-org-all-deny" v-model="contract_type">
              <label for="contract-type-billing-org-all-deny">Billing,Organizations All Deny</label>
            </div>
            <div class='has-operation'>
              <input type="checkbox" id="has-operation" value="has-operation" v-model="has_operation">
              <label for="has-operation">保守契約はありますか？</label>
            </div>
            <div>
              <p>利用する処理(Process)を選択してください。</p>
              <select @change="onChangeProcess">
              <option v-for="(process, index) in processes" :key="index" :value="process.value" :disabled="process.disabled">
                {{ process.text }}
              </option>
              </select>
            </div>
            <p>{{ result_message }} </p>
            <br/>

            <button type="button" v-on:click="this.setupChildAccount">実行</button>
          </div> 

          <div v-if="this.selected_operation == 'ope_SetupCurUserAccount'">
            <div class="access-key">
              <div class="_ak">
                <div class="_f-left">
                  <label class="form-element__label" for="resolutionLeft">アクセスキー</label>
                </div>
                <div class="_f-right">
                  <input type="text" v-model="access_key" name="AccessKey" placeholder="AKIxxxxxx" maxlength="50" autofocus required>
                </div>
              </div>
            </div>
            <div class="secret-key">
              <div class="_sk">
                <div class="_f-left">
                  <label class="form-element__label" for="resolutionLeft">シークレットキー</label>
                </div>
                <div class="_f-right">
                  <input type="text" v-model="secret_key" name="SecretAccessKey" placeholder="xxxxxxxxxx" maxlength="50">
                </div>
              </div>
            </div>

            <p>{{ result_message }} </p>
            <br/>

            <button type="button" v-on:click="this.setupCurUserAccount">実行</button>
          </div>

          <div v-if="this.selected_operation == 'ope_SetupFederationModeAccount'">
            <div class="access-key">
              <div class="_ak">
                <div class="_f-left">
                  <label class="form-element__label" for="resolutionLeft">アクセスキー</label>
                </div>
                <div class="_f-right">
                  <input type="text" v-model="access_key" name="AccessKey" placeholder="AKIxxxxxx" maxlength="50" autofocus required>
                </div>
              </div>
            </div>
            <div class="secret-key">
              <div class="_sk">
                <div class="_f-left">
                  <label class="form-element__label" for="resolutionLeft">シークレットキー</label>
                </div>
                <div class="_f-right">
                  <input type="text" v-model="secret_key" name="SecretAccessKey" placeholder="xxxxxxxxxx" maxlength="50">
                </div>
              </div>
            </div>
            <div>
              <p>利用する処理(Process)を選択してください。</p>
              <select @change="onChangeProcess">
              <option v-for="(process, index) in processes" :key="index" :value="process.value" :disabled="process.disabled">
                {{ process.text }}
              </option>
              </select>
            </div>
            <p>{{ result_message }} </p>
            <br/>

            <button type="button" v-on:click="this.setupFederationModeAccount">実行</button>
          </div>

          <div v-if="this.selected_operation == 'ope_SetupReadOnlyUserChildAccount'">
            <div class="access-key">
              <div class="_ak">
                <div class="_f-left">
                  <label class="form-element__label" for="resolutionLeft">アクセスキー</label>
                </div>
                <div class="_f-right">
                  <input type="text" v-model="access_key" name="AccessKey" placeholder="AKIxxxxxx" maxlength="50" autofocus required>
                </div>
              </div>
            </div>
            <div class="secret-key">
              <div class="_sk">
                <div class="_f-left">
                  <label class="form-element__label" for="resolutionLeft">シークレットキー</label>
                </div>
                <div class="_f-right">
                  <input type="text" v-model="secret_key" name="SecretAccessKey" placeholder="xxxxxxxxxx" maxlength="50">
                </div>
              </div>
            </div>
            <p>{{ result_message }} </p>
            <br/>

            <button type="button" v-on:click="this.setupReadOnlyUserChildAccount">実行</button>
          </div>

          <div v-if="this.selected_operation == 'ope_InitPrimaryAccount'">
            <div class="access-key">
              <div class="_ak">
                <div class="_f-left">
                  <label class="form-element__label" for="resolutionLeft">アクセスキー</label>
                </div>
                <div class="_f-right">
                  <input type="text" v-model="access_key" name="AccessKey" placeholder="AKIxxxxxx" maxlength="50" autofocus required>
                </div>
              </div>
            </div>
            <div class="secret-key">
              <div class="_sk">
                <div class="_f-left">
                  <label class="form-element__label" for="resolutionLeft">シークレットキー</label>
                </div>
                <div class="_f-right">
                  <input type="text" v-model="secret_key" name="SecretAccessKey" placeholder="xxxxxxxxxx" maxlength="50">
                </div>
              </div>
            </div>
            <div>
              <input type="checkbox" id="is-create-federation-role" value="is-create-federation-role" v-model="is_create_federation_role">
              <label for="is-create-federation-role">フェデレーションRoleを作成しますか？</label>
            </div>
            <div>
              <p>利用する処理(Process)を選択してください。</p>
              <select @change="onChangeProcess">
              <option v-for="(process, index) in processes" :key="index" :value="process.value" :disabled="process.disabled">
                {{ process.text }}
              </option>
              </select>
            </div>
            <p>{{ result_message }} </p>
            <br/>

            <button type="button" v-on:click="this.setupPrimaryAccount">実行</button>
          </div>
          <div v-if="this.selected_operation == 'ope_SetupIamUserPrimaryAccount'">
            <div class="access-key">
              <div class="_ak">
                <div class="_f-left">
                  <label class="form-element__label" for="resolutionLeft">アクセスキー</label>
                </div>
                <div class="_f-right">
                  <input type="text" v-model="access_key" name="AccessKey" placeholder="AKIxxxxxx" maxlength="50" autofocus required>
                </div>
              </div>
            </div>
            <div class="secret-key">
              <div class="_sk">
                <div class="_f-left">
                  <label class="form-element__label" for="resolutionLeft">シークレットキー</label>
                </div>
                <div class="_f-right">
                  <input type="text" v-model="secret_key" name="SecretAccessKey" placeholder="xxxxxxxxxx" maxlength="50">
                </div>
              </div>
            </div>
            <div>
                <div class="_f-left">
                  <label class="form-element__label" for="resolutionLeft">IAMユーザーを作成したいLinkedアカウントIDを改行区切りで入力</label>
                </div>
                <div class="_f-right">
                  <textarea v-model="linked_accounts" placeholder="123456789012"></textarea>
                </div>
            </div>

            <p>{{ result_message }} </p>
            <br/>

            <button type="button" v-on:click="this.setupIamUserPrimaryAccount">実行</button>
          </div> 

          <div v-else-if="selindex == 2">
            <p></p>
          </div>
          <div v-else>
            <p>操作を選択してください</p>
          </div>
        </div>
        <div>
          <p>質問・うまく動作しないなど、何かありましたら、<a href="https://cloudpack.slack.com/archives/CHE3RJCD8" target="_blank">#pj-bo-support-tools</a> までご連絡ください。</p>
        </div>
        <div>
        </div>
      </form>
    </div>
  </div>
</template>


<script>
  import { AmplifyEventBus } from 'aws-amplify-vue'
  import { Auth } from 'aws-amplify'

  import Amplify, { API } from 'aws-amplify';
  import awsconfig from './aws-exports';

  Amplify.configure(awsconfig);

  export default {
    name: 'app',
    async beforeCreate() {
      try {
        this.user = await Auth.currentAuthenticatedUser()
        this.signedIn = true
      } catch (err) {
        console.log(`Error: ${err}`);
        this.signedIn = false
      }
      AmplifyEventBus.$on('authState', async info => {
        if (info === 'signedIn') {
          this.signedIn = true
          this.user = await Auth.currentAuthenticatedUser()
        } else {
          this.signedIn = false
          this.user = undefined
        }
      });
      console.log(`username = ${this.user.username}`);
    },
    data () {
      return {
        access_key: "",
        secret_key: "",
        child_accounts: "",
        linked_accounts: "",
        is_create_cur_user: true,
        is_create_federation_role: true,
        contract_type: "contract-type-invoice",

        operations: [
          { value: 'x', text: '操作を選択してください：', disabled: false },
          { value: 'ope_InitOrganizations', text: '請求アカウント初期設定', disabled: false },
          { value: 'ope_CreateChildren',    text: '子アカウント群作成',    disabled: false },
          { value: 'ope_MoveChildren',      text: '子アカウント群移管受入', disabled: false },
          { value: 'ope_EndChildren',       text: '子アカウント群解除',    disabled: false },
          { value: 'ope_SetupCurUserAccount', text: 'CURユーザー作成', disabled: false },
          { value: 'x', text: '--- 以下はPrimaryアカウント向け操作 ---', disabled: true },
          { value: 'ope_InitPrimaryAccount', text: 'CUR提供ありPrimaryアカウント初期設定', disabled: false },
          { value: 'ope_SetupIamUserPrimaryAccount', text: 'CUR提供ありPrimaryアカウントIAMユーザー作成', disabled: false },
          { value: 'x', text: '--- 以下は子アカウント向け操作 ---', disabled: true },
          { value: 'ope_SetupChildAccount', text: '子アカウント初期設定',   disabled: false },
          { value: 'ope_SetupReadOnlyUserChildAccount', text: 'ReadOnlyUser設定',   disabled: false },
          { value: 'x', text: '--- 以下は全アカウント向け操作 ---', disabled: true },
          { value: 'ope_SetupFederationModeAccount', text: 'フェデレーションモード有効化',   disabled: false },
        ],
        selected_operation: 'x',

        processes: [
          { value: 'process1', text: 'Process1', disabled: false },
          { value: 'process2', text: 'Process2', disabled: false },
          { value: 'process3', text: 'Process3', disabled: false },
          { value: 'process4', text: 'Process4', disabled: false },
          { value: 'process5', text: 'Process5', disabled: false },
          { value: 'process6', text: 'Process6', disabled: false },
          { value: 'process7', text: 'Process7', disabled: false },
          { value: 'process8', text: 'Process8', disabled: false },
          { value: 'process9', text: 'Process9', disabled: false },
          { value: 'process10', text: 'Process10', disabled: false },
          { value: 'process11', text: 'Process11', disabled: false },
          { value: 'process12', text: 'Process12', disabled: false },
          { value: 'process13', text: 'Process13', disabled: false },
          { value: 'process14', text: 'Process14', disabled: false },
          { value: 'process15', text: 'Process15', disabled: false },
          { value: 'process16', text: 'Process16', disabled: false },
          { value: 'process17', text: 'Process17', disabled: false },
          { value: 'process18', text: 'Process18', disabled: false },
          { value: 'process19', text: 'Process19', disabled: false },
          { value: 'process20', text: 'Process20', disabled: false },
        ],
        selected_process: 'process1',

        result_message: "",

        user: undefined,
        signedIn: false
      }
    },

    methods: {
      window:onload = function () {
        console.log("load");
      },
      onChangeOperation: function(e) {
        let index = e.target.selectedIndex;
        let operation = this.operations[index];

        this.selected_operation = operation.value
      },
      onChangeProcess: function(e) {
        let index = e.target.selectedIndex;
        let process = this.processes[index];

        this.selected_process = process.value
      },
      setupPayerAccount: function(){
        this.result_message = 'Starting...'

        console.log("setupPayerAccount");
        console.log(this.user.username);

        const param = {
          body: {
            user: this.user.username,
            access_key: this.access_key,
            secret_key: this.secret_key,
            is_create_cur_user: this.is_create_cur_user,
            function_type: 'SetupPayerAccount',
            process: this.selected_process
          },
          headers: {}, // OPTIONAL
          response: true,
        };

        this.postAPI(param)
      },
      createChildrenAccounts: function(){
        this.result_message = 'Starting...'

        console.log("createChildren");
        console.log(this.user.username);

        const param = {
          body: {
            user: this.user.username,
            access_key: this.access_key,
            secret_key: this.secret_key,
            child_accounts: this.child_accounts,
            is_create: 'True',
            function_type: 'RegisterChildrenAccounts'
          },
          headers: {}, // OPTIONAL
          response: true,
        };

        this.postAPI(param)
      },
      moveChildrenAccounts: function(){
        this.result_message = 'Starting...'

        console.log("moveChildren");
        console.log(this.user.username);

        const param = {
          body: {
            user: this.user.username,
            access_key: this.access_key,
            secret_key: this.secret_key,
            child_accounts: this.child_accounts,
            is_create: 'False',
            function_type: 'RegisterChildrenAccounts'
          },
          headers: {}, // OPTIONAL
          response: true,
        };

        this.postAPI(param)
      },
      endChildrenAccounts: function(){
        this.result_message = 'Starting...'

        console.log("moveChildren");
        console.log(this.user.username);

        const param = {
          body: {
            user: this.user.username,
            access_key: this.access_key,
            secret_key: this.secret_key,
            child_accounts: this.child_accounts,
            is_retain: 'True',
            function_type: 'DeregisterChildrenAccounts'
          },
          headers: {}, // OPTIONAL
          response: true,
        };

        this.postAPI(param)
      },


      setupChildAccount: function(){
        this.result_message = 'Starting...'

        console.log("setupChildAccount");
        console.log(this.user.username);

        const param = {
          body: {
            user: this.user.username,
            access_key: this.access_key,
            secret_key: this.secret_key,
            contract_type: this.contract_type,
            has_operation: this.has_operation,
            function_type: 'SetupChildAccount',
            process: this.selected_process
          },
          headers: {}, // OPTIONAL
          response: true,
        };

        this.postAPI(param)
      },

      setupCurUserAccount: function(){
        this.result_message = 'Starting...'

        console.log("setupCurUserAccount");
        console.log(this.user.username);

        const param = {
          body: {
            user: this.user.username,
            access_key: this.access_key,
            secret_key: this.secret_key,
            function_type: 'SetupCurUserAccount'
          },
          headers: {}, // OPTIONAL
          response: true,
        };

        this.postAPI(param)
      },

      setupFederationModeAccount: function(){
        this.result_message = 'Starting...'

        console.log("setupFederationModeAccount");
        console.log(this.user.username);

        const param = {
          body: {
            user: this.user.username,
            access_key: this.access_key,
            secret_key: this.secret_key,
            function_type: 'SetupFederationModeAccount',
            process: this.selected_process
          },
          headers: {}, // OPTIONAL
          response: true,
        };

        this.postAPI(param)
      },

      setupReadOnlyUserChildAccount: function(){
        this.result_message = 'Starting...'

        console.log("setupReadOnlyUserChildAccount");
        console.log(this.user.username);

        const param = {
          body: {
            user: this.user.username,
            access_key: this.access_key,
            secret_key: this.secret_key,
            contract_type: 'contract-type-read-only-user', // 子アカウント作成のフローに合わせて、contract_typeを固定でセット
            function_type: 'SetupReadOnlyUserChildAccount'
          },
          headers: {}, // OPTIONAL
          response: true,
        };

        this.postAPI(param)
      },

      setupPrimaryAccount: function(){
        this.result_message = 'Starting...'

        console.log("setupPrimaryAccount");
        console.log(this.user.username);

        const param = {
          body: {
            user: this.user.username,
            access_key: this.access_key,
            secret_key: this.secret_key,
            is_create_federation_role: this.is_create_federation_role,
            function_type: 'SetupPrimaryAccount',
            process: this.selected_process
          },
          headers: {}, // OPTIONAL
          response: true,
        };

        this.postAPI(param)
      },
      setupIamUserPrimaryAccount: function(){
        this.result_message = 'Starting...'

        console.log("setupIamUserPrimaryAccount");
        console.log(this.user.username);

        const param = {
          body: {
            user: this.user.username,
            access_key: this.access_key,
            secret_key: this.secret_key,
            linked_accounts: this.linked_accounts,
            function_type: 'SetupIamUserPrimaryAccount'
          },
          headers: {}, // OPTIONAL
          response: true,
        };

        this.postAPI(param)
      },
      postAPI: function(param){
        // メモ：処理ごとにAPI別ける方が正攻法と思うが、当面はいろいろ要望多そうなので敢えて１APIで進める。仕様要件固まってきたら個別APIにしてもいいかも
        const apiName = 'apiBoSupportToolsFrontpage'; 
        const path = '/items'; 

        API
          .post(apiName, path, param)
          .then(response => {
            // Add your code here
            console.log(response);
            this.result_message = response.data.message
          })
          .catch(error => {
            console.log(error.response);
            this.result_message = error.response.data.message
          });
      }
    }
  }
</script>


<style>
body {
  margin: 0
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
.signout {
  background-color: #ededed;
  margin: 0;
  padding: 11px 0px 1px;
}
</style>